import { configureStore, combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { PersistConfig, persistReducer } from 'redux-persist';
import authzReducer from '../Components/features/OtpValidation/authzSlice';
import mobileInfoReducer from '../Components/features/ContactNumber/mobileSlice';
import emailReducer from '../Components/features/Email/emailSlice';
import nameReducer from '../Components/features/PersonalDetails/nameSlice';
import statusCodeReducer from '../Components/features/Sidebar/statusCodeSlice';
import autoFillReducer from '../AutoFillDetailsSlice';
import encryptedDetailsReducer from '../encryptedDetailsSlice';
import { DEVELOPMENT, LOCAL } from '../constants/constants';

const reducers = combineReducers({
  authz: authzReducer,
  mobileInfo: mobileInfoReducer,
  partnerProvidedInfo: autoFillReducer,
  encryptedInfo: encryptedDetailsReducer,
  email: emailReducer,
  name: nameReducer,
  statusCode: statusCodeReducer,
});

const persistConfig: PersistConfig<any, any, any> = {
  key: 'root',
  storage,
  whitelist: ['authz', 'mobileInfo', 'statusCode', 'email', 'name'],
};
const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools:
    process.env.REACT_APP_ENV === LOCAL ||
    process.env.REACT_APP_ENV === DEVELOPMENT,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
