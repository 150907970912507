import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IEncryptedData {
  encryptedData: string;
  partnerName: string;
}

export interface AutofillState {
  redirected: IEncryptedData;
}

const initialState: AutofillState = {
  redirected: {
    encryptedData: '',
    partnerName: '',
  },
};

export const encrypteddDetailsSlice = createSlice({
  name: 'encryptedDetails',
  initialState,
  reducers: {
    setEncryptedInfo: (
      state,
      action: PayloadAction<IEncryptedData>,
    ) => {
      state.redirected = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setEncryptedInfo } = encrypteddDetailsSlice.actions;

export default encrypteddDetailsSlice.reducer;
