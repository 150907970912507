import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Iemail {
  email: string;
}
const initialState: Iemail = {
  email: '',
};

export const emailSlice = createSlice({
  name: 'email',
  initialState,
  reducers: {
    emailAction: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
  },
});

export const { emailAction } = emailSlice.actions;
export default emailSlice.reducer;
