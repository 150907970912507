import { IAutoFillResponse } from '../AutoFillDetailsSlice';

export const WEB = 'WEB';
export const PRODUCTION = 'production';
export const DEVELOPMENT = 'development';
export const LOCAL = 'local';
export const ONESCORE = 'ONESCORE';
export const ONECARD = 'ONECARD';

export const EMPTY_DETAILS: IAutoFillResponse = {
  email: '',
  basicDetails: {
    pan: '',
    dob: '',
    firstName: '',
    lastName: '',
    gender: '',
  },
  addressDetails: {
    line1: '',
    line2: '',
    line3: '',
    pinCode: null,
    country: '',
  },
  resultFound: false,
};
