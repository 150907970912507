import { useQuery, QueryFunction } from 'react-query';

import { AxiosError, AxiosResponse } from 'axios';

interface ISuccessCallback<J> {
  // eslint-disable-next-line no-unused-vars
  (key: AxiosResponse<J, any>): void;
}

interface IErrorCallback {
  // eslint-disable-next-line no-unused-vars
  (err: AxiosError<any, any>): void;
}

export const useBffQueryApi = <J, K>(
  cacheIdentity: string | Array<string | K>,
  fn: QueryFunction<AxiosResponse<J>>,
  enabled: boolean = true,
  stop: boolean = true,
  onSuccess: ISuccessCallback<J> | undefined = undefined,
  onError: IErrorCallback | undefined = undefined,
  refetchOnWindowFocus: boolean = false,
) => {
  return useQuery<AxiosResponse<J>, AxiosError>(cacheIdentity, fn, {
    retry: 0,
    cacheTime: 0,
    enabled,
    refetchInterval: stop ? false : 6000,
    onSuccess,
    onError,
    refetchOnWindowFocus,
  });
};
