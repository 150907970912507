import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface BasicDetails {
  pan: string;
  dob: string;
  firstName: string;
  lastName: string;
  gender: string;
}

export interface AddressDetails {
  line1: string;
  line2: string;
  line3: string;
  pinCode: number | null;
  country: string;
}

export interface IAutoFillResponse {
  email: string;
  basicDetails: BasicDetails;
  addressDetails: AddressDetails;
  resultFound: boolean;
}

export interface AutofillState {
  autofill: IAutoFillResponse | null | undefined;
}

const initialState: AutofillState = {
  autofill: null,
};

export const autoFillSlice = createSlice({
  name: 'autoFillDetailsEncryptedInfo',
  initialState,
  reducers: {
    setAutoFillInfo: (
      state,
      action: PayloadAction<IAutoFillResponse | undefined>,
    ) => {
      state.autofill = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAutoFillInfo } = autoFillSlice.actions;

export default autoFillSlice.reducer;
