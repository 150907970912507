// import React from 'react';
import './style.css';
import { useNavigate } from 'react-router-dom';
// import { Cards } from './Cards';
// import { OneCardfeaturelist } from '../../Common/FeaturesList';

export function InitScreen() {
  const navigate = useNavigate();
  navigate('/onescoreweb/phone');
  // const onButtonSubmit = () => {
  //   navigate('/onescoreweb');
  // };
  return null;
  // <div className="z-40 bg-graypattern sm:bg-bgpattern bg-cover h-screen w-screen">
  //   <section className="main-section">
  //     <section className="hero-section">
  //       <div className="introducing_one_card">
  //         <div className="OneCard_heading">
  //           <h1>
  //             Say Hi to <span>OneCard</span>
  //           </h1>
  //         </div>
  //         <div className="flauntMetal">
  //           <h5>#FlauntYourMetal</h5>
  //         </div>
  //         <div className="card-info">
  //           <p>
  //             India’s best metal credit card. Built with full-stack
  //             tech. Backed by the principles of simplicity,
  //             transparency, and giving back control to the user.
  //           </p>
  //         </div>
  //         <div className="OneCard_apply">
  //           <button
  //             type="button"
  //             className="applyNow"
  //             onClick={onButtonSubmit}
  //           >
  //             Get OneCard Now!
  //           </button>
  //         </div>
  //       </div>
  //       {/* cards */}
  //       <div className="w-full sm:hidden flex flex-wrap justify-center mt-7">
  //         {OneCardfeaturelist.map((singlecard) => {
  //           return (
  //             <Cards
  //               image={singlecard.imgUrl}
  //               subtext={singlecard.text}
  //             />
  //           );
  //         })}
  //       </div>

  //       <div className="OneCard_image w-full sm:w-auto ">
  //         <div className="w-full flex justify-center sm:hidden my-5 ">
  //           <img
  //             className="h-10 w-16"
  //             alt="OneCard"
  //             src="https://www.getonecard.app/assets/apply/onecard_logo.svg"
  //           />
  //         </div>
  //         <div className="flex w-full justify-center sm:h-auto	">
  //           <img
  //             className="hero_image w-auto h-64 sm:w-full sm:h-full  ml-3 md:w-4/5 hidden sm:block"
  //             src="https://www.getonecard.app/assets/apply/onecard_with_smoke2.png"
  //             alt=""
  //           />
  //           <img
  //             className=" w-auto h-60  sm:hidden "
  //             src="https://www.getonecard.app/assets/apply/land_hero_image.png"
  //             alt=""
  //           />
  //           {/* <div className="w-auto sm:hidden flex justify-center flex-col">
  //             <p>
  //               <h3 className="text-xl font-semibold">The best</h3>
  //               <h2 className="text-2xl font-bold text-confirmbtn-500">
  //                 Metal Card
  //               </h2>
  //               <h3 className="text-xl font-semibold">
  //                 for the best
  //               </h3>
  //               <h3 className="text-xl font-semibold">
  //                 version of you!
  //               </h3>
  //             </p>
  //           </div> */}
  //         </div>
  //       </div>
  //     </section>
  //     <section className="fees-section">
  //       <div>
  //         <div className="Fees_Text_Section">
  //           <h1 className="No_Hidden_Fees">No Hidden Fees Ever.</h1>
  //           <div className="Fees_Sub_Text">
  //             <span>No Joining Fees. </span>
  //             <br />
  //             <span>No Annual Fees.</span>
  //             <br />
  //             <span>No Rewards Redemption Fees.</span>
  //           </div>
  //         </div>
  //       </div>
  //     </section>
  //     <section className="powerful_app_section seamless_onboarding_section ">
  //       <div className="mock-up-img-className ">
  //         <div className=" ">
  //           <img
  //             className="hero_image seamless_onboarding_image"
  //             src="https://www.getonecard.app/assets/apply/powerful_app.png"
  //             alt="powerful_app"
  //           />
  //         </div>
  //       </div>
  //       <div className="section-text">
  //         <div className="icon_image flex justify-end">
  //           <div>
  //             <img
  //               src="https://www.getonecard.app/images/feature/control_app_icon.svg"
  //               alt="control app icon"
  //             />
  //           </div>
  //         </div>
  //         <div className="section_heading">
  //           <div>
  //             <h3>
  //               Powerful <br />
  //               mobile app
  //             </h3>
  //           </div>
  //         </div>
  //         <div className="section_subText">
  //           <div className="inner_div_subText">
  //             <p>
  //               Control all aspects of your credit card from the
  //               powerful OneCard app - transaction limit,
  //               domestic/international use, online/offline use,
  //               contactless payments and much more.
  //             </p>
  //           </div>
  //         </div>
  //       </div>
  //     </section>
  //     <section className="metal-section">
  //       <div className="metal-div">
  //         <div className="reward_image">
  //           <div>
  //             <img
  //               src="https://www.getonecard.app/images/feature/metal_icon.svg"
  //               alt="feature metal icon"
  //             />
  //           </div>
  //         </div>
  //         <div className="metal_heading">
  //           <div>
  //             <h3>It’s metal</h3>
  //           </div>
  //         </div>
  //         <div className="metal_subText">
  //           <div className="inner_div_metal_subText">
  //             <p>
  //               A credit card that is exclusive, and exquisitely
  //               crafted with metal, because you deserve only the
  //               best. Why settle for plastic cards anymore?
  //             </p>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="Metal_image">
  //         <div>
  //           <img
  //             className="metal_img"
  //             src="https://www.getonecard.app/assets/apply/its_metal.png"
  //             alt="its_metal"
  //           />
  //         </div>
  //       </div>
  //     </section>
  //     <section className="seamless_onboarding_section">
  //       <div className="mock-up-img-className">
  //         <div>
  //           <img
  //             className="hero_image seamless_onboarding_image"
  //             src="https://www.getonecard.app/assets/apply/seamless_onboarding.png"
  //             alt="Seamless on-boarding"
  //           />
  //         </div>
  //       </div>
  //       <div className="section-text">
  //         <div className="icon_image flex justify-end">
  //           <div>
  //             <img
  //               src="https://www.getonecard.app/images/feature/kyc_icon.svg"
  //               alt="kyc_icon"
  //             />
  //           </div>
  //         </div>
  //         <div className="section_heading">
  //           <div>
  //             <h3>
  //               Seamless <br />
  //               on-boarding
  //             </h3>
  //           </div>
  //         </div>
  //         <div className="section_subText">
  //           <div className="inner_div_subText">
  //             <p>
  //               Get started using a completely digital on-boarding
  //               process, and activate your OneCard in less than 5
  //               minutes.
  //             </p>
  //           </div>
  //         </div>
  //       </div>
  //     </section>
  //     <section className="reward-section">
  //       <div className="reward-div">
  //         <div className="reward_image">
  //           <div>
  //             <img
  //               src="https://www.getonecard.app/images/feature/rewards_icon.svg"
  //               alt="rewards_icon"
  //             />
  //           </div>
  //         </div>
  //         <div className="reward_heading">
  //           <div>
  //             <h3>
  //               5X rewards on <br />
  //               your top spends
  //             </h3>
  //           </div>
  //         </div>
  //         <div className="reward_subText">
  //           <div className="inner_div_reward_subText">
  //             <p>
  //               5X rewards on Top 2 spend categories. Points are
  //               credited instantly and never expire. Just swipe
  //               right to redeem. No more rounding off, get even
  //               fractional points.
  //             </p>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="mock-up-img-className">
  //         <div>
  //           <img
  //             className="hero_image seamless_onboarding_image"
  //             src="https://www.getonecard.app/assets/apply/5x_rewards.png"
  //             alt=""
  //           />
  //         </div>
  //       </div>
  //     </section>
  //     <section className="EMI-option-section">
  //       <div className="mock-up-img-className">
  //         <div>
  //           <img
  //             className="hero_image seamless_onboarding_image"
  //             src="https://www.getonecard.app/assets/apply/flexible_emi.png"
  //             alt="Seamless on-boarding"
  //           />
  //         </div>
  //       </div>
  //       <div className="EMI_text">
  //         <div className="icon_image">
  //           <div className="text-right flex justify-end">
  //             <img
  //               src="https://www.getonecard.app/images/feature/emi.svg"
  //               alt="EMI_icon"
  //             />
  //           </div>
  //         </div>
  //         <div className="EMI_heading">
  //           <div>
  //             <h3>
  //               Flexible EMI
  //               <br />
  //               options
  //             </h3>
  //           </div>
  //         </div>
  //         <div className="EMI_subText">
  //           <div className="inner_div_EMI_subText">
  //             <p>
  //               Shop in full, pay in parts. No calls, no emails, no
  //               documentation. Get points on EMI transactions, repay
  //               EMIs with reward points. Manage EMIs from the EMI
  //               Dashboard.
  //             </p>
  //           </div>
  //         </div>
  //       </div>
  //     </section>
  //     <section className="suggest-section">
  //       <div className="suggest-div">
  //         <div className="suggest-img hidden sm:block">
  //           <img
  //             className="img"
  //             src="https://www.getonecard.app/images/logo_white.svg"
  //             alt="onecard logo"
  //           />
  //         </div>
  //         <div className="suggest-img hidden sm:block">
  //           <img
  //             src="https://www.getonecard.app/images/icons/crafted%20with%20love.svg"
  //             alt="crafted with love"
  //           />
  //         </div>
  //         <div className="suggest-heading">
  //           <h4>We are all ears!</h4>
  //         </div>
  //         <div className="suggest-subheading">
  //           <p>We are a new kid on the block, eager to learn.</p>
  //           <p>All ideas and suggestions are welcome.</p>
  //         </div>
  //       </div>
  //     </section>
  //     <section className="Banking-Partners ">
  //       <div className="Banking-partners-image  flex justify-center mb-5 sm:mb-0  ">
  //         <img
  //           src="https://www.getonecard.app/assets/apply/banking_partner_landpage.svg"
  //           alt="banking partners"
  //         />
  //       </div>
  //       <div>
  //         <section className="CopyRights hidden sm:block ">
  //           <p>Copyright © 2022 FPL Technologies Pvt. Ltd.</p>
  //         </section>
  //       </div>
  //     </section>
  //     <div className="suggest-img flex  w-full justify-center sm:hidden">
  //       <img
  //         src="https://www.getonecard.app/images/icons/crafted%20with%20love.svg"
  //         alt="crafted with love"
  //       />
  //     </div>
  //   </section>

  //   <section className="Bottom_Navbar hidden md:block">
  //     <div className="Bottom_div">
  //       <div className="Bottom_Nav_Image">
  //         <img
  //           className="bottom_img"
  //           src="https://www.getonecard.app/assets/apply/bottom_card.svg"
  //           alt="bottom_card"
  //         />
  //       </div>
  //       <div className="Bottom_Nav_button">
  //         <button
  //           onClick={onButtonSubmit}
  //           type="button"
  //           className="applyNow bottom_btn"
  //         >
  //           Get OneCard Now!
  //         </button>
  //       </div>
  //     </div>
  //   </section>
  // </div>
}
