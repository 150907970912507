import React, { useContext } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import {
  appStatus,
  IHeaderInformation,
  IStatusAPIResponse,
} from '../../../api/appStatus';
import { useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import { useBffQueryApi } from '../../../hooks/useQueryBffApi';
import { CustomButton } from '../../Button/CustomButton';
import { CommonLoader } from '../../Common/CommonLoader';
import { MODAL } from '../../Common/ConstantStrings';
import { PopupContext } from '../../Common/Custompopups';

export function ExperianMaxTries() {
  const navigate = useNavigate();
  const popupContext = useContext(PopupContext);

  const authzToken = useAppSelector(
    (state: RootState) => state.authz.AuthzInfo,
  );

  const { data: response, isLoading } = useBffQueryApi<
    IStatusAPIResponse,
    IHeaderInformation
  >(
    'status',
    () => appStatus({ auth: authzToken }),
    true,
    true,
    async () => {},
    async (error) => {
      if (error.response?.status === 401) {
        popupContext.showPopup('Session Expired Please Login', MODAL);
      }
    },
  );

  if (isLoading) {
    return <CommonLoader />;
  }

  // if (error) {
  //   if (error.response?.status === 401) {
  //     // navigate('/onescoreweb/phone');
  //     alert('Session Expired please Login');
  //     dispatch(number(''));
  //     dispatch(setAuthzInfo(''));
  //     dispatch(setCustomerNumber(''));
  //   }
  //   navigate('/onescoreweb');
  // }

  if (!(response?.data.status === 57)) {
    return <Navigate to="/onescoreweb" />;
  }

  const retryFlow = () => {
    navigate('/onescoreweb');
  };
  return (
    <div className="h-auto  pb-5 mt-28 flex flex-col justify-evenly font-Manrope md:pl-20  w-auto px-5 md:px-0 2xl:mt-44 ">
      <div className=" w-full h-auto flex items-center flex-wrap  ">
        <div className="md:w-3/4 w-160  flex flex-col  justify-center md:items-baseline md:pb-5 pb-0    ">
          <p className="text-3xl   w-full   font-medium  text-center md:text-left 2xl:text-4xl">
            Experian report max tries exceeded.
          </p>
          <div className=" text-subtextcolor-100 text-center md:text-left w-full my-5 2xl:text-2xl   ">
            <p className="">please try again after 24 hours.</p>
          </div>
          <div className="flex justify-center md:justify-start w-full   ">
            <CustomButton
              btntext="Retry"
              clickEvent={retryFlow}
              isbuttonactive
            />
          </div>
        </div>

        <div
          className=" flex  w-full md:w-auto  justify-center
       sm:justify-start  "
        >
          <div className=" h-auto ">
            <div className="relative h-60 w-72 2xl:h-72 2xl:w-80">
              <img
                src="https://www.getonecard.app/assets/apply/cibil_failed.svg"
                alt="Address Success"
                className=" absolute h-full w-full"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
