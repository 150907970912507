import React, { useContext, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import TagManager from 'react-gtm-module';
// import * as Sentry from '@sentry/react';
import { useBffQueryApi } from '../hooks/useQueryBffApi';
import {
  appStatus,
  IStatusAPIResponse,
  IHeaderInformation,
} from '../api/appStatus';
import { useAppSelector } from '../app/hooks';
import { RootState } from '../app/store';
import { CommonLoader } from './Common/CommonLoader';
import { apiCatch } from '../utils/apiCatchSentry';
import { PopupContext } from './Common/Custompopups';
import { MODAL } from './Common/ConstantStrings';
import { statusCodeAction } from './features/Sidebar/statusCodeSlice';
import { clearUserSession } from './Common/RemoveUserSession';
import { DataLayer } from './Common/Datalayer';
import { emailAction } from './features/Email/emailSlice';
import { nameAction } from './features/PersonalDetails/nameSlice';

export function OneScoreWeb() {
  const location = useLocation();
  const dispatch = useDispatch();
  const authzToken = useAppSelector(
    (state: RootState) => state.authz.AuthzInfo,
  );

  const emailId = useAppSelector(
    (state: RootState) => state.email.email,
  );

  const userName = useAppSelector(
    (state: RootState) => state.name.name,
  );

  // eslint-disable-next-line no-unused-vars
  const [stop, setStop] = useState(true);
  const popupContext = useContext(PopupContext);

  const {
    data: response,
    error,
    isLoading,
  } = useBffQueryApi<IStatusAPIResponse, IHeaderInformation>(
    'status',
    () => appStatus({ auth: authzToken }),
    true,
    stop,
    async () => {},
    async (err) => {
      if (err.response?.status === 401) {
        popupContext.showPopup('Session Expired Please Login', MODAL);
      }
    },
  );

  if (response?.data?.customerDetails?.email && !emailId) {
    dispatch(emailAction(response.data.customerDetails.email));
  }

  if (response?.data?.customerDetails?.name && !userName) {
    dispatch(nameAction(response.data.customerDetails.name));
  }

  const redirecter = (status: number) => {
    if (status === 41) {
      return (
        <Navigate
          to="/onescoreweb/email-confirmation"
          state={{ location: location.pathname }}
        />
      );
    }

    if (status === 42) {
      return (
        <Navigate
          to="/onescoreweb/personal-details"
          state={{ location: location.pathname }}
        />
      );
    }

    if (status === 43) {
      return (
        <Navigate
          to="/onescoreweb/pan-inprogress"
          state={{ location: location.pathname }}
        />
      );
    }

    if (status === 44) {
      return (
        <Navigate
          to="/onescoreweb/address-details"
          state={{ location: location.pathname }}
        />
      );
    }

    if (status === 45) {
      return (
        <Navigate
          to="/onescoreweb/address-inprogress"
          state={{ location: location.pathname }}
        />
      );
    }

    if (status === 46) {
      return (
        <Navigate
          to="/onescoreweb/cibil"
          state={{ location: location.pathname }}
        />
      );
    }

    if (status === 49) {
      return <Navigate to="/onescoreweb/status" />;
    }

    if (status === 56) {
      return (
        <Navigate
          to="/onescoreweb/cibil/max-attempts"
          state={{ location: location.pathname }}
        />
      );
    }

    if (status) {
      return (
        <Navigate
          to="/onescoreweb/score-page"
          state={{
            location: location.pathname,
            sanctionedAmount: response?.data.limit,
            productCode: response?.data.productCode,
            bankId: response?.data.bankId,
            experianScore: response?.data.experianScore,
            cibilScore: response?.data.cibilScore,
            error,
            auth: true,
          }}
        />
      );
    }

    // if (status === 47) {
    //   return (
    //     <Navigate
    //       to="/onescoreweb/experian"
    //       state={{ location: location.pathname }}
    //     />
    //   );
    // }

    // if (status === 48) {
    //   return (
    //     <Navigate
    //       to="/onescoreweb/declined"
    //       state={{ location: location.pathname }}
    //     />
    //   );
    // }

    // if (status === 51) {
    //   return (
    //     <Navigate
    //       to="/onescoreweb/pre-book"
    //       state={{
    //         location: location.pathname,
    //         limit: response?.data.limit,
    //         reserved: response?.data.reserved,
    //         preBookingId: response?.data.preBookingId,
    //       }}
    //     />
    //   );
    // }

    // if (status === 53) {
    //   return (
    //     <Navigate
    //       to="/onescoreweb/waitlist"
    //       state={{ location: location.pathname }}
    //     />
    //   );
    // }

    // if (status === 54) {
    //   return (
    //     <Navigate
    //       to="/onescoreweb/offer-expired"
    //       state={{ location: location.pathname }}
    //     />
    //   );
    // }

    // if (status === 52) {
    //   return (
    //     <Navigate
    //       to="/onescoreweb/unserviceable"
    //       state={{ location: location.pathname }}
    //     />
    //   );
    // }

    // if (status === 55) {
    //   return (
    //     <Navigate
    //       to="/onescoreweb/existing-customer"
    //       state={{ location: location.pathname }}
    //     />
    //   );
    // }

    // if (status === 57) {
    //   return (
    //     <Navigate
    //       to="/onescoreweb/experian/max-attempts"
    //       state={{ location: location.pathname }}
    //     />
    //   );
    // }

    return <Navigate to="/onescoreweb/error" />;
  };

  if (isLoading) {
    return <CommonLoader />;
  }

  if (error?.response?.status === 401) {
    clearUserSession();
    // alert('Session Expired please Login');
    return <Navigate to="/onescoreweb/phone" />;
  }

  if (error?.response?.status === 500) {
    apiCatch(error);
    return <Navigate to="/onescoreweb/error" />;
  }

  if (!response || !response.data || !response.data.status) {
    const statusResponse: DataLayer = {
      dataLayer: {
        page: 'statusPage',
        statusCode: 'Status Missing',
      },
    };
    TagManager.dataLayer(statusResponse);
    // TagManager.initialize({
    //   gtmId: `GTM-${process.env.REACT_APP_GTMID}`,
    //   auth: process.env.REACT_APP_GTMAUTH,
    //   preview: process.env.REACT_APP_GTMENV,
    //   dataLayer: {
    //     statusCode: 'Status Missing',
    //     response,
    //     page: 'statusPage',
    //   },
    //   dataLayerName: 'StatusDataLayer',
    // });
    return <Navigate to="/onescoreweb/error" />;
  }

  const { status } = response!.data;
  dispatch(statusCodeAction(status));

  const ApplicationStatusApi: DataLayer = {
    dataLayer: { page: 'statusPage', statusCode: status.toString() },
    dataLayerName: 'StatusDataLayer',
  };
  TagManager.dataLayer(ApplicationStatusApi);

  // TagManager.initialize({
  //   gtmId: `GTM-${process.env.REACT_APP_GTMID}`,
  //   auth: process.env.REACT_APP_GTMAUTH,
  //   preview: process.env.REACT_APP_GTMENV,
  //   dataLayer: {
  //     statusCode: status,
  //     page: 'statusPage',
  //   },
  //   dataLayerName: 'StatusDataLayer',
  // });

  return redirecter(status);
}
