import React from 'react';
import { useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import { ProgressBarContainer } from '../../ProgressBars/ProgressBarContainer';

const MobileViewProgressBar = () => {
  const statusCode: number = useAppSelector(
    (state: RootState) => state.statusCode.statusCode,
  );

  let step = 0;
  switch (statusCode) {
    case 41:
      step = 1;
      break;
    case 42:
      step = 2;
      break;
    case 43:
      step = 2;
      break;
    case 44:
      step = 3;
      break;
    case 45:
      step = 3;
      break;
    default:
      break;
  }

  const isReachedArray: boolean[] = [
    true,
    step === 2 || step === 3,
    step === 3,
  ];
  return (
    <div className="flex flex-col h-9 w-16 fixed top-10 right-5 lg:hidden justify-center gap-y-1 items-center">
      <ProgressBarContainer
        isReachedArray={isReachedArray}
        barWidth="w-mobileviewstep"
      />
      <div className="text-xs">
        <span className="text-activeButton">Step {step}</span> of 3
      </div>
    </div>
  );
};
export default MobileViewProgressBar;
