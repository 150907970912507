import axios from 'axios';

export interface ICustomerDetails {
  name?: string;
  email?: string;
}
export interface IStatusAPIResponse {
  addressNeeded: boolean;
  applied: boolean;
  backendAddressProcessing: boolean;
  backendPanProcessing: boolean;
  backendPerfiosProcessing: boolean;
  cibilNeeded: boolean;
  cibilScore: number;
  emailNeeded: boolean;
  epfNeeded: boolean;
  experianNeeded: boolean;
  experianScore: number;
  fdInterestRate: number;
  interestRate: number;
  limit: number;
  metalDeclined: boolean;
  metalNonServiceable: boolean;
  offerId: string;
  panNeeded: boolean;
  perfiosNeeded: boolean;
  productCode: string;
  reserved: boolean;
  status: number;
  preBookingId: string;
  bankId: number;
  customerDetails?: ICustomerDetails;
}

export interface IHeaderInformation {
  auth: string;
}

export const appStatus = async (params: IHeaderInformation) => {
  const { auth } = params;
  const response = await axios.get<IStatusAPIResponse>(
    `${process.env.REACT_APP_BFF_URL}/web/v1/underwriting/application-status`,
    {
      headers: {
        'authorization-info': `${auth}`,
        'Content-Type': 'application/json',
        'channel-type': 'ONESCORE_WEB',
      },
      // eslint-disable-next-line radix
      timeout: parseInt(
        process.env.REACT_APP_STATUS_API_TIMEOUT as string,
      ),
    },
  );
  return response;
};
