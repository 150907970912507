import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AuthorizationState {
  AuthzInfo: string;
  CustomerNumber: string;
}

const initialState: AuthorizationState = {
  AuthzInfo: '',
  CustomerNumber: '',
};

export const authorizationSlice = createSlice({
  name: 'authzInfo',
  initialState,
  reducers: {
    setAuthzInfo: (state, action: PayloadAction<string>) => {
      state.AuthzInfo = action.payload;
    },
    setCustomerNumber: (state, action: PayloadAction<string>) => {
      state.CustomerNumber = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAuthzInfo, setCustomerNumber } =
  authorizationSlice.actions;

export default authorizationSlice.reducer;
