import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface InitalPhoneNumberState {
  phoneNumber: string;
}

const initialState: InitalPhoneNumberState = {
  phoneNumber: '',
};

export const mobileSlice = createSlice({
  name: 'phoneInfo',
  initialState,
  reducers: {
    number: (state, action: PayloadAction<string>) => {
      state.phoneNumber = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { number } = mobileSlice.actions;

export default mobileSlice.reducer;
