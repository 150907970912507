import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Istatus {
  statusCode: number;
}

const initialState: Istatus = {
  statusCode: 0,
};

export const statusCodeSlice = createSlice({
  name: 'statusCode',
  initialState,
  reducers: {
    statusCodeAction: (state, action: PayloadAction<number>) => {
      state.statusCode = action.payload;
    },
  },
});

export const { statusCodeAction } = statusCodeSlice.actions;
export default statusCodeSlice.reducer;
