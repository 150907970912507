/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, {
  Suspense,
  useContext,
  useEffect,
  useState,
} from 'react';
// import logo from './logo.svg';
import './App.css';
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { UseMutationResult } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { OneScoreWeb } from './Components/OneOptimisticRedirecter';
import { InitScreen } from './Components/features/InitialScreen/InitScreen';
import ErrorBoundary from './error/errorBoundary';
import { CommonError } from './Components/features/CommonError/CommonError';
import { CommonLoader } from './Components/Common/CommonLoader';
import { ExperianMaxTries } from './Components/features/Experian/ExperianMaxTries';
import { OfferExpired } from './Components/features/OfferStatus/OfferExpired';
import { Iutmheaders } from './Components/features/ContactNumber/MobileNumber';
import { setEncryptedInfo } from './encryptedDetailsSlice';
import { logoutUser } from './api/logout';
import { useBffApi } from './hooks/useBffApi';
import { useAppSelector } from './app/hooks';
import { RootState } from './app/store';
import { number } from './Components/features/ContactNumber/mobileSlice';
import BeforeLoginSideBar from './Components/features/Sidebar/BeforeLoginSideBar';
import AfterLoginSideBar from './Components/features/Sidebar/AfterLoginSideBar';
import {
  setAuthzInfo,
  setCustomerNumber,
} from './Components/features/OtpValidation/authzSlice';
import { ProtectiontoAllRoute } from './app/ProtectiontoAllRoute';
import { ProtectPhoneOTPScreen } from './app/ProtectedRoutes';
import {
  CustomePopups,
  PopupContext,
} from './Components/Common/Custompopups';
import { MODAL } from './Components/Common/ConstantStrings';
import oneScoreLogo from './images/OneScore_logo_black.svg';
import MobileViewProgressBar from './Components/features/Sidebar/MobileViewProgressBar';

const Prebook = React.lazy(() =>
  import('./Components/features/OfferStatus/Prebook').then(
    (module) => ({
      default: module.Prebook,
    }),
  ),
);

const CibilMaxTries = React.lazy(() =>
  import('./Components/features/Cibil/CibilMaxTries').then(
    (module) => ({
      default: module.CibilMaxTries,
    }),
  ),
);

const PaninProgress = React.lazy(() =>
  import('./Components/features/OfferStatus/Panvalidation').then(
    (module) => ({
      default: module.Panvalidation,
    }),
  ),
);

const AddressInProgress = React.lazy(() =>
  import('./Components/features/AddressDetails/AddressPending').then(
    (module) => ({
      default: module.AddressPending,
    }),
  ),
);
const KnowMoreAbout = React.lazy(() =>
  import('./Components/features/OfferStatus/KnowMoreAbout').then(
    (module) => ({
      default: module.KnowMoreAbout,
    }),
  ),
);

const ExperianError = React.lazy(() =>
  import('./Components/features/Experian/ExperianError').then(
    (module) => ({
      default: module.ExperianError,
    }),
  ),
);

const ExistingCustomer = React.lazy(() =>
  import('./Components/features/OfferStatus/ExistingCustomer').then(
    (module) => ({
      default: module.ExistingCustomer,
    }),
  ),
);

const Panvalidation = React.lazy(() =>
  import('./Components/features/OfferStatus/Panvalidation').then(
    (module) => ({
      default: module.Panvalidation,
    }),
  ),
);

const ExperianFetching = React.lazy(() =>
  import('./Components/features/Experian/ExperianFetching').then(
    (module) => ({
      default: module.ExperianFetching,
    }),
  ),
);

const CibilKbaAuthFail = React.lazy(() =>
  import(
    './Components/features/Cibil/CibilErrors/CibilKbaAuthFail'
  ).then((module) => ({
    default: module.CibilKbaAuthFail,
  })),
);
const LocationUnServiceable = React.lazy(() =>
  import(
    './Components/features/OfferStatus/LocationUnServiceable'
  ).then((module) => ({
    default: module.LocationUnServiceable,
  })),
);

const CibilOtp = React.lazy(() =>
  import('./Components/features/Cibil/CibilOtp').then((module) => ({
    default: module.CibilOtp,
  })),
);

const WaitList = React.lazy(() =>
  import('./Components/features/OfferStatus/WaitList').then(
    (module) => ({
      default: module.WaitList,
    }),
  ),
);

const MobileNumber = React.lazy(() =>
  import('./Components/features/ContactNumber/MobileNumber').then(
    (module) => ({ default: module.MobileNumber }),
  ),
);

const Otp = React.lazy(() =>
  import('./Components/features/OtpValidation/Otp').then(
    (module) => ({ default: module.Otp }),
  ),
);

const PersonalDetail = React.lazy(() =>
  import('./Components/features/PersonalDetails/PersonalDetail').then(
    (module) => ({ default: module.PersonalDetail }),
  ),
);

const AddressDetail = React.lazy(() =>
  import('./Components/features/AddressDetails/AddressDetail').then(
    (module) => ({ default: module.AddressDetail }),
  ),
);
const AddressSuccess = React.lazy(() =>
  import('./Components/features/AddressDetails/AddressSuccess').then(
    (module) => ({ default: module.AddressSuccess }),
  ),
);

const Email = React.lazy(() =>
  import('./Components/features/Email/Email').then((module) => ({
    default: module.Email,
  })),
);

const ApplicationDeclined = React.lazy(() =>
  import(
    './Components/features/OfferStatus/ApplicationDeclined'
  ).then((module) => ({
    default: module.ApplicationDeclined,
  })),
);

// const FinalOffer = React.lazy(() =>
//   import('./Components/features/OfferStatus/FinalOffer').then(
//     (module) => ({
//       default: module.FinalOffer,
//     }),
//   ),
// );

const SecureOrUnsecure = React.lazy(() =>
  import('./Components/features/OfferStatus/SecureOrUnsecure').then(
    (module) => ({
      default: module.SecureOrUnsecure,
    }),
  ),
);

const Kba = React.lazy(() =>
  import('./Components/features/Cibil/Kba').then((module) => ({
    default: module.Kba,
  })),
);

const Kbaflowinitiation = React.lazy(() =>
  import('./Components/features/Cibil/Kbaflowintitation').then(
    (module) => ({ default: module.Kbaflowinitiation }),
  ),
);

const CibilFetching = React.lazy(() =>
  import('./Components/features/Cibil/CibilFetching').then(
    (module) => ({
      default: module.CibilFetching,
    }),
  ),
);

const CibilServerError = React.lazy(() =>
  import('./Components/features/Cibil/CibilErrors/ServerFail').then(
    (module) => ({
      default: module.ServerFail,
    }),
  ),
);

const CibilAuthStatus = React.lazy(() =>
  import('./Components/features/Cibil/CibilErrors/CibilAuth').then(
    (module) => ({
      default: module.CibilAuth,
    }),
  ),
);

const ApplicationStatusPoller = React.lazy(() =>
  import('./Components/features/applicationStatusPoller').then(
    (module) => ({
      default: module.ApplicationStatusPoller,
    }),
  ),
);

const Termsandcondition = React.lazy(() =>
  import(
    './Components/features/TermsAndCondition/Termsandcondition'
  ).then((module) => ({ default: module.Termsandcondition })),
);

export const App = CustomePopups(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const popupContext = useContext(PopupContext);

  const authzToken = useAppSelector(
    (state: RootState) => state.authz.AuthzInfo,
  );
  const [utmHeaders, setutmHeaders] = useState<Iutmheaders>({
    utm_campaign: '',
    utm_medium: '',
    utm_id: '',
    utm_source: '',
    utm_content: '',
  });
  const para = new URLSearchParams(location.search);
  useEffect(() => {
    if (para.get('start-page') === 'app') {
      if (para.get('dataAttribute') && para.get('partner_name')) {
        dispatch(
          setEncryptedInfo({
            encryptedData:
              encodeURIComponent(
                para.get('dataAttribute') as string,
              ) ?? '',
            partnerName: para.get('partner_name') ?? '',
          }),
        );
      }
      navigate('onescoreweb/phone');
    }
    let temp = { ...utmHeaders };
    Object.keys(utmHeaders).forEach((elem) => {
      if (para.get(elem) !== null) {
        temp = { ...temp, [elem]: para.get(elem) };
      }
    });
    setutmHeaders(temp);
  }, []);

  const { mutate } = useBffApi<
    string,
    Function,
    UseMutationResult<AxiosResponse<any>, AxiosError>
  >(logoutUser);

  const logoutEventHandle = () => {
    mutate(
      { authz: authzToken },
      {
        onSuccess: async (response) => {
          navigate('onescoreweb/phone');
          dispatch(number(''));
          dispatch(setAuthzInfo(''));
          dispatch(setCustomerNumber(''));
        },
        onError: async (err) => {
          if (err.response?.status === 401) {
            popupContext.showPopup(
              'Session Expired Please Login!',
              MODAL,
            );
          }
        },
      },
    );
  };

  const containerCss =
    'h-containerHeightMobile lg:pl-32 flex flex-col items-center lg:items-start lg:pt-0 pt-10% min-h-containerMinHeightMobile lg:min-h-containerMinHeight lg:justify-start lg:gap-y-5 overflow-y-auto lg:h-70vh relative';

  const buttonCss =
    ' rounded-xl lg:text-xl font-medium lg:w-56 lg:h-14 h-11 w-4/5 lg:mt-1 lg:mb-0 flex items-center justify-center absolute bottom-ctaButtonSpacingLg';
  return (
    <>
      {location.pathname !== '/' &&
      location.pathname !== '/onescoreweb/score-page' &&
      location.pathname !== '/onescoreweb/error' &&
      location.pathname !== '/onescoreweb/underwriting/server-down' &&
      location.pathname !== '/onescoreweb/cibil/max-attempts' &&
      location.pathname !== '/onescoreweb/score-page/knowmore' &&
      location.pathname !== '/onescoreweb/cibil/kba-fail' ? (
        <>
          <div className="bg-white fixed top-0 left-0 lg:w-70vw hidden lg:block pb-10 z-10">
            <img
              height="50"
              width="100"
              src={oneScoreLogo}
              className="mt-10 ml-32"
              alt="One Score Logo"
            />
          </div>
          <div className="h-5 w-screen fixed top-0 lg:hidden bg-activeButton z-20" />
        </>
      ) : null}

      {location.pathname === '/onescoreweb/phone' ||
      location.pathname === '/onescoreweb/phone-otp-confirmation' ? (
        <BeforeLoginSideBar />
      ) : null}

      {location.pathname !== '/' &&
      location.pathname !== '/onescoreweb/score-page' &&
      location.pathname !== '/onescoreweb/score-page/knowmore' &&
      location.pathname !== '/onescoreweb/phone' &&
      location.pathname !== '/onescoreweb/phone-otp-confirmation' &&
      location.pathname !== '/onescoreweb/error' &&
      location.pathname !== '/onescoreweb/cibil' &&
      location.pathname !== '/onescoreweb/pan-inprogress' &&
      location.pathname !== '/onescoreweb/address-inprogress' &&
      location.pathname !== '/onescoreweb/cibil/status' &&
      location.pathname !== '/onescoreweb/underwriting/server-down' &&
      location.pathname !== '/onescoreweb/cibil/max-attempts' &&
      location.pathname !== '/onescoreweb/cibil/kba-fail' ? (
        <AfterLoginSideBar />
      ) : null}

      {location.pathname === '/onescoreweb/email-confirmation' ||
      location.pathname === '/onescoreweb/personal-details' ||
      location.pathname === '/onescoreweb/address-details' ? (
        <MobileViewProgressBar />
      ) : null}

      <div
        className={`box-border font-Inter ${
          location.pathname !== '/' &&
          location.pathname !== '/onescoreweb/score-page' &&
          location.pathname !== '/onescoreweb/error' &&
          location.pathname !== '/onescoreweb/cibil' &&
          location.pathname !== '/onescoreweb/pan-inprogress' &&
          location.pathname !== '/onescoreweb/address-inprogress' &&
          location.pathname !== '/onescoreweb/cibil/status' &&
          location.pathname !==
            '/onescoreweb/underwriting/server-down' &&
          location.pathname !== '/onescoreweb/score-page/knowmore' &&
          location.pathname !== '/onescoreweb/cibil/max-attempts' &&
          location.pathname !== '/onescoreweb/cibil/kba-fail'
            ? 'lg:w-70vw mt-5 lg:mt-36'
            : ''
        } flex flex-col h-auto box-border`}
      >
        <Routes>
          <Route element={<ProtectPhoneOTPScreen />}>
            <Route path="/" element={<InitScreen />} />
            <Route
              path="onescoreweb/phone"
              element={
                <Suspense fallback={<CommonLoader />}>
                  <MobileNumber
                    containerCss={containerCss}
                    buttonCss={buttonCss}
                  />
                </Suspense>
              }
            />

            <Route
              path="onescoreweb/phone-otp-confirmation"
              element={
                <Suspense fallback={<CommonLoader />}>
                  <Otp
                    utm_campaign={utmHeaders.utm_campaign}
                    utm_id={utmHeaders.utm_id}
                    utm_medium={utmHeaders.utm_medium}
                    utm_source={utmHeaders.utm_source}
                    utm_content={utmHeaders.utm_content}
                    containerCss={containerCss}
                    buttonCss={buttonCss}
                  />
                </Suspense>
              }
            />
          </Route>
          <Route element={<ProtectiontoAllRoute />}>
            <Route
              path="onescoreweb/personal-details"
              element={
                <Suspense fallback={<CommonLoader />}>
                  <PersonalDetail
                    containerCss={containerCss}
                    buttonCss={buttonCss}
                  />
                </Suspense>
              }
            />
            <Route
              path="onescoreweb/address-details"
              element={
                <Suspense fallback={<CommonLoader />}>
                  <AddressDetail
                    containerCss={containerCss}
                    buttonCss={buttonCss}
                  />
                </Suspense>
              }
            />
            <Route
              path="onescoreweb/email-confirmation"
              element={
                <Suspense fallback={<CommonLoader />}>
                  <Email
                    containerCss={containerCss}
                    buttonCss={buttonCss}
                  />
                </Suspense>
              }
            />
            <Route
              path="onescoreweb/score-page"
              element={
                <Suspense fallback={<CommonLoader />}>
                  <SecureOrUnsecure />
                </Suspense>
              }
            />
            <Route
              path="onescoreweb/score-page/knowmore"
              element={
                <Suspense fallback={<CommonLoader />}>
                  <KnowMoreAbout />
                </Suspense>
              }
            />
            <Route
              path="onescoreweb/score-page/terms-and-condition"
              element={
                <Suspense fallback={<CommonLoader />}>
                  <Termsandcondition />
                </Suspense>
              }
            />

            {/* <Route
              path="onescoreweb/offer-expired"
              element={
                <Suspense fallback={<CommonLoader />}>
                  <OfferExpired />
                </Suspense>
              }
            /> */}
            <Route path="onescoreweb" element={<OneScoreWeb />} />
            {/* <Route
              path="onescoreweb/declined"
              element={
                <Suspense fallback={<CommonLoader />}>
                  <ApplicationDeclined />
                </Suspense>
              }
            /> */}
            <Route
              path="onescoreweb/cibil"
              element={
                <Suspense fallback={<CommonLoader />}>
                  <CibilFetching />
                </Suspense>
              }
            />
            <Route
              path="onescoreweb/cibil/otp"
              element={
                <Suspense fallback={<CommonLoader />}>
                  <CibilOtp
                    containerCss={containerCss}
                    buttonCss={buttonCss}
                  />
                </Suspense>
              }
            />
            <Route
              path="onescoreweb/underwriting/server-down"
              element={
                <Suspense fallback={<CommonLoader />}>
                  <CibilServerError />
                </Suspense>
              }
            />

            <Route
              path="onescoreweb/cibil/status"
              element={
                <Suspense fallback={<CommonLoader />}>
                  <CibilAuthStatus />
                </Suspense>
              }
            />
            <Route
              path="onescoreweb/cibil/kba"
              element={
                <Suspense fallback={<CommonLoader />}>
                  <ErrorBoundary>
                    <Kba
                      containerCss={containerCss}
                      buttonCss={buttonCss}
                    />
                  </ErrorBoundary>
                </Suspense>
              }
            />
            <Route
              path="onescoreweb/cibil/kbainitiation"
              element={
                <Suspense fallback={<CommonLoader />}>
                  <Kbaflowinitiation
                    containerCss={containerCss}
                    buttonCss={buttonCss}
                  />
                </Suspense>
              }
            />
            <Route
              path="onescoreweb/status"
              element={
                <Suspense fallback={<CommonLoader />}>
                  <ApplicationStatusPoller />
                </Suspense>
              }
            />
            <Route
              path="onescoreweb/cibil/kba-fail"
              element={
                <Suspense fallback={<CommonLoader />}>
                  <CibilKbaAuthFail />
                </Suspense>
              }
            />
            <Route
              path="onescoreweb/cibil/max-attempts"
              element={
                <Suspense fallback={<CommonLoader />}>
                  <CibilMaxTries />
                </Suspense>
              }
            />
            {/* <Route
              path="onescoreweb/unserviceable"
              element={
                <Suspense fallback={<CommonLoader />}>
                  <LocationUnServiceable />
                </Suspense>
              }
            /> */}
            <Route
              path="onescoreweb/pre-book"
              element={
                <Suspense fallback={<CommonLoader />}>
                  <Prebook />
                </Suspense>
              }
            />
            {/* <Route
              path="onescoreweb/waitlist"
              element={
                <Suspense fallback={<CommonLoader />}>
                  <WaitList />
                </Suspense>
              }
            /> */}
            <Route
              path="onescoreweb/error"
              element={
                <Suspense fallback={<CommonLoader />}>
                  <CommonError />
                </Suspense>
              }
            />
            <Route
              path="onescoreweb/experian"
              element={
                <Suspense fallback={<CommonLoader />}>
                  <ExperianFetching />
                </Suspense>
              }
            />
            <Route
              path="onescoreweb/experian/max-attempts"
              element={
                <Suspense fallback={<CommonLoader />}>
                  <ExperianMaxTries />
                </Suspense>
              }
            />
            <Route
              path="onescoreweb/experian/error"
              element={
                <Suspense fallback={<CommonLoader />}>
                  <ExperianError />
                </Suspense>
              }
            />
            {/* <Route
              path="onescoreweb/existing-customer"
              element={
                <Suspense fallback={<CommonLoader />}>
                  <ExistingCustomer />
                </Suspense>
              }
            /> */}
            <Route
              path="onescoreweb/pan-inprogress"
              element={
                <Suspense fallback={<CommonLoader />}>
                  <PaninProgress />
                </Suspense>
              }
            />
            <Route
              path="onescoreweb/address-inprogress"
              element={
                <Suspense fallback={<CommonLoader />}>
                  <AddressInProgress />
                </Suspense>
              }
            />
            <Route
              path="onescoreweb/pan-validation/status"
              element={
                <Suspense fallback={<CommonLoader />}>
                  <Panvalidation />
                </Suspense>
              }
            />
            {/* <Route
              path="onescoreweb/address-success"
              element={
                <Suspense fallback={<CommonLoader />}>
                  <AddressSuccess />
                </Suspense>
              }
            /> */}
            {/* <Route
              path="onescoreweb/offerExpired"
              element={
                <Suspense fallback={<CommonLoader />}>
                  <OfferExpired />
                </Suspense>
              }
            /> */}
          </Route>
        </Routes>
      </div>
    </>
  );
});

// bg-customedarkblue-400
/* {location.pathname !== '/onescoreweb/phone' &&
        location.pathname !== '/' &&
        location.pathname !== '/onescoreweb/phone-otp-confirmation' &&
        authzToken !== '' ? (
          <button
            type="button"
            className=" absolute bg-logoutbuttongradient right-4 top-5 h-8 lg:h-10 2xl:h-11 w-auto text-sm  lg:text-base 2xl:text-xl   px-3 py-1 rounded-md  flex items-center justify-center "
            onClick={logoutEventHandle}
          >
            <span className="tracking-wide">Logout</span>
            <span className="h-3 w-3 lg:w-4 lg:h-4 2xl:h-5 2xl:w-5 ml-2 lg:ml-3 2xl:ml-4 ">
              <img
                src="https://www.getonecard.app/assets/apply/log-out.svg"
                alt="logout"
              />
            </span>
          </button>
        ) : null} */
