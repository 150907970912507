import { useDispatch } from 'react-redux';
import { number } from '../features/ContactNumber/mobileSlice';
import {
  setAuthzInfo,
  setCustomerNumber,
} from '../features/OtpValidation/authzSlice';

export const clearUserSession = () => {
  const dispatch = useDispatch();
  dispatch(number(''));
  dispatch(setAuthzInfo(''));
  dispatch(setCustomerNumber(''));
};
