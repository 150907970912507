import React, { useState } from 'react';
import { useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';

import processTick from '../../../images/Process Tick.svg';
import scoreMeter from '../../../images/Score Meter.svg';

const AfterLoginSideBar = () => {
  const [steps] = useState([
    'Create Account',
    'Email Id',
    'Personal Details',
    'Address Details',
  ]);

  const statusCode = useAppSelector(
    (state: RootState) => state.statusCode.statusCode,
  );

  let active: number;

  if (statusCode === 41) active = 2;
  else if (statusCode === 42 || statusCode === 43) active = 3;
  else if (statusCode === 44 || statusCode === 45) active = 4;
  else if (
    (statusCode >= 46 && statusCode <= 48) ||
    (statusCode >= 50 && statusCode <= 53)
  )
    active = 5;

  const heightPercent = ((active! - 2) / 4) * 100;

  return (
    <div className="z-sideBar fixed w-30vw bottom-0 top-0 right-0 rounded-l-4xl shadow-sidebarShadow hidden lg:flex flex-col items-center justify-center bg-white gap-y-14">
      <p className="text-2xl text-numberColor font-bold w-3/5">
        Process
      </p>
      <div className="flex justify-center-center w-3/5">
        <div className="flex flex-col gap-y-10 items-center relative">
          {steps.map((step, index) => {
            return (
              <div className="flex items-center relative w-14 justify-center">
                <img
                  src={processTick}
                  alt=""
                  height="40px"
                  width="40px"
                  className={`${
                    index + 1 < active ||
                    (!active && index === 0) ||
                    (index + 1 === active && active === 5)
                      ? 'block'
                      : 'hidden'
                  } z-10`}
                />
                <div
                  className={`${
                    index + 1 >= active || (!active && index > 0)
                      ? 'flex justify-center items-center'
                      : 'hidden'
                  } h-14 w-14 ${
                    index + 1 > active || !active
                      ? 'border-none'
                      : 'border-4 border-sidebargreen'
                  } rounded-full bg-stepsBg z-10`}
                >
                  {index + 1}
                </div>
                <p className="absolute left-20 min-w-max">{step}</p>
              </div>
            );
          })}
          <div className="flex items-center relative">
            <img
              src={scoreMeter}
              alt=""
              height="56px"
              width="56px"
              className="z-10"
            />
            <p className="absolute min-w-max left-20">
              Check Credit Score
            </p>
          </div>
          <div className="absolute h-full border-r border-black border-dashed flex">
            <div
              className="absolute border border-sidebargreen"
              style={{
                height: `${
                  heightPercent <= 75 ? `${heightPercent}%` : '75%'
                }`,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default AfterLoginSideBar;
