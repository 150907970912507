import axios from 'axios';

interface IRequestDetails {
  authz: string;
}

export const logoutUser = async (details: IRequestDetails) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_BFF_URL}/web/v1/logout`,
    {
      headers: {
        'authorization-info': `${details.authz}`,
        'Content-Type': 'application/json',
        'channel-type': 'ONESCORE_WEB',
      },
    },
  );

  return response;
};
