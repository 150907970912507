import React from 'react';
import { IndividualBar } from './IndividualBar';

interface Iprops {
  isReachedArray: boolean[];
  barWidth: string;
}

export const ProgressBarContainer: React.FC<Iprops> = (
  props: Iprops,
) => {
  const { barWidth, isReachedArray } = props;
  return (
    <>
      <div className="flex justify-between w-full">
        {isReachedArray.map((isReached) => {
          return (
            <IndividualBar
              isReached={isReached}
              barWidth={barWidth}
            />
          );
        })}
      </div>
    </>
  );
};
