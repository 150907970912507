import React from 'react';

interface Iprops {
  isReached: boolean;
  barWidth: string;
}

export const IndividualBar: React.FC<Iprops> = (props: Iprops) => {
  const { barWidth, isReached } = props;
  return (
    <div
      className={`${barWidth} h-0.5 lg:h-1 ${
        isReached
          ? 'bg-activeButton'
          : 'bg-placeholderbackgroundcolor'
      } rounded-md`}
    />
  );
};
