import * as Sentry from '@sentry/browser';
import { AxiosError } from 'axios';
import { store } from '../app/store';

export const apiCatch = async (error: AxiosError) => {
  const state = store.getState();
  Sentry.configureScope(async (scope) =>
    scope
      .setLevel(Sentry.Severity.Error)
      .setUser({ id: state.authz.CustomerNumber })
      .setExtra('error', error),
  );
  // Sentry.showReportDialog(); - If you want get users feedback on error
  Sentry.captureException(error);
};
