import React from 'react';
import TagManager from 'react-gtm-module';
// import * as Sentry from '@sentry/browser';
import oneScoreWhiteIcon from '../../../images/OneScore_white_black.svg';
import oneScoreMobileImage from '../../../images/onescore_2_0_mockups.svg';
import playStoreImage from '../../../images/Play Store.svg';
import appStoreImage from '../../../images/App Store.svg';
import oneScoreDownload from '../../../images/onescore_download.png';
import { DataLayer } from '../../Common/Datalayer';
// import { useNavigate } from 'react-router-dom';
// import { CustomButton } from '../../Button/CustomButton';
// import { GetLink } from '../../Common/GetLink';
// import { ONECARD } from '../../../constants/constants';

export const CommonError = () => {
  // const navigate = useNavigate();
  // const retry = () => {
  //   navigate('/onescoreweb');
  // };
  // const handleContactUsButton = () => {
  //   window.open('mailto:help@getonecard.app');
  // };

  // Sentry.captureMessage('User in Common Error Page');

  const ErrorPage = (
    <div className="h-screen relative">
      <img
        src={oneScoreWhiteIcon}
        alt="One Score Logo"
        className="absolute lg:top-16 lg:left-20 top-oneScoreLogoSpacing left-10% lg:h-16 lg:w-20 h-oneScoreLogo-percent w-oneScoreLogo-percent"
      />
      <div className="lg:h-blueBlock h-1/5 bg-activeButton flex flex-col lg:flex-row items-center lg:items-center lg:justify-between gap-y-10 lg:gap-y-0 w-full lg:px-24">
        <img
          src={oneScoreMobileImage}
          alt=""
          className="relative lg:top-64 top-mobileImage lg:h-mobileImage lg:w-mobileImage h-mobileImage-vh w-80"
        />
        <div className="flex flex-col lg:gap-y-5 gap-y-2 lg:w-2/5 lg:h-4/5 lg:justify-end w-90% mt-oopsSpacing lg:mt-0 lg:text-white">
          <p className="text-sm lg:text-3xl font-light">
            Oops! Something went wrong..
          </p>
          <h1 className=" text-lg lg:text-5xl font-bold">
            Please try again in sometime or use our app
          </h1>
          <p className="text-base font-medium lg:text-xl lg:font-bold tracking-wide">
            From tracking your credit score to helping you improve
            your score. <br />
          </p>
          <div>
            <p className="text-base font-medium lg:text-xl lg:font-bold tracking-wide">
              OneScore does it all!
            </p>
            <p className="text-sm">
              No Ads. No Spam. Free credit score from CIBIL and
              Experian.{' '}
            </p>
          </div>
        </div>
      </div>
      <div className="lg:h-auto lg:pt-10 h-15% flex lg:flex-row lg:justify-end w-full lg:items-center lg:px-32 lg:mt-0 lg:mb-0 relative top-downloadOneScore lg:static justify-center">
        <div className="flex flex-col lg:w-2/5 lg:h-4/5 lg:gap-y-10 gap-y-5 w-90% items-start lg:items-stretch">
          <p className="lg:text-xl text-base font-medium text-numberColor">
            Download OneScore Now
          </p>
          <div className="flex items-center gap-x-14">
            <div className="flex items-center gap-x-1 lg:flex-col lg:items-start lg:justify-around lg:h-32">
              <div className="w-40">
                <img
                  alt="play store"
                  src={playStoreImage}
                  className=""
                />
              </div>
              <div className="w-40">
                <img
                  alt="app store"
                  src={appStoreImage}
                  className=""
                />
              </div>
            </div>
            <p className="ml-2 hidden lg:block">OR</p>
            <div className="hidden lg:block w-32 border-4 border-sidebargreen p-1 rounded-qrCodeRadius relative h-32">
              <img
                src={oneScoreDownload}
                alt="QR code"
                className="h-full w-full z-10"
              />
              <div className="pseudoDiv1" />{' '}
              {/* Look in index.css for the css */}
              <div className="pseudoDiv2" />{' '}
              {/* Look in index.css for the css */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  const commonErrorPage: DataLayer = {
    dataLayer: {
      page: 'commonErrorPage',
    },
    dataLayerName: 'errorDataLayer',
  };
  TagManager.dataLayer(commonErrorPage);
  // TagManager.initialize({
  //   gtmId: `GTM-${process.env.REACT_APP_GTMID}`,
  //   auth: process.env.REACT_APP_GTMAUTH,
  //   preview: process.env.REACT_APP_GTMENV,
  //   dataLayer: {
  //     page: 'commonErrorPage',
  //   },
  //   dataLayerName: 'errorDataLayer',
  // });
  return ErrorPage;
};
