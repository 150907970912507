/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAppSelector } from './hooks';
import { RootState } from './store';

export const ProtectPhoneOTPScreen = () => {
  const authzToken = useAppSelector(
    (state: RootState) => state.authz.AuthzInfo,
  );

  const location = useLocation();

  return (
    <>
      {authzToken ? (
        <Navigate to="/onescoreweb" replace />
      ) : (
        <Outlet />
      )}
    </>
  );
};
