/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, createContext } from 'react';
import { useDispatch } from 'react-redux';
import { MODAL, TOAST } from '../Common/ConstantStrings';
import { number } from '../features/ContactNumber/mobileSlice';
import {
  setAuthzInfo,
  setCustomerNumber,
} from '../features/OtpValidation/authzSlice';

type IToast = {
  showPopup: (message: string, type: string) => void;
  clearUserSession: () => void;
};

export const PopupContext = createContext<IToast>({
  showPopup: () => null,
  clearUserSession: () => null,
});

const TOAST_DELAY = 2000;

export const CustomePopups = (OriginalComponent: React.FC) => {
  function Toast(props: any) {
    const [toastmessage, setToastMessage] = useState<string | null>(
      null,
    );
    const [isModalOrToast, setIsModalOrToast] = useState<
      string | null
    >();
    const dispatch = useDispatch();
    const hideToast = () => {
      setTimeout(() => setToastMessage(null), TOAST_DELAY);
    };

    const showPopup = (message: string, type: string) => {
      setToastMessage(message);
      setIsModalOrToast(type);
      if (type === TOAST) {
        hideToast();
      }
    };
    const closeModal = () => {
      setToastMessage(null);
    };
    const clearUserSession = () => {
      dispatch(number(''));
      dispatch(setAuthzInfo(''));
      dispatch(setCustomerNumber(''));
    };

    const handleLoginAgain = () => {
      clearUserSession();
      closeModal();
    };

    return (
      <PopupContext.Provider
        value={{
          showPopup,
          clearUserSession,
        }}
      >
        <OriginalComponent showPopup={showPopup} {...props} />
        {isModalOrToast !== null && isModalOrToast === TOAST
          ? toastmessage != null && (
              <div className="absolute bottom-10 flex justify-center items-center  w-full">
                <div className=" w-auto h-auto text-sm bg-successgress-100 rounded-lg px-2 py-1">
                  <h1>{toastmessage}</h1>
                </div>
              </div>
            )
          : toastmessage != null && (
              <div
                className="modal fixed top-0 left-0 w-full h-screen outline-none fade bg-modalbg flex items-center justify-center z-modal"
                id="exampleModalTwo"
                tabIndex={-1}
                role="dialog"
              >
                <div
                  className="modal-dialog relative w-100 pointer-events-none max-w-lg mx-auto px-4 sm:px-0"
                  role="document"
                >
                  <div className="relative flex flex-col w-full pointer-events-auto bg-white rounded-lg">
                    <div className="flex justify-end p-2  rounded-t">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        onClick={closeModal}
                      >
                        &times;
                      </button>
                    </div>

                    <div className="flex ml-8 ">
                      <img
                        src="https://www.getonecard.app/assets/apply/session_expired.svg"
                        alt="time"
                        className="h-10 w-10 sm:h-12 sm:w-12"
                      />
                      <div className="ml-3">
                        <h2 className="text-lg md:text-2xl font-bold">
                          Session Expired
                        </h2>
                        <p className="text-sm md:text-lg font-normal">
                          Please Login!
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center justify-end p-2 2xl:p-4 ">
                      <button
                        type="button"
                        className="inline-block font-normal text-center px-8 py-1 leading-normal text-base rounded cursor-pointer text-white bg-confirmbtn-500"
                        data-dismiss="modal"
                        onClick={handleLoginAgain}
                      >
                        Login
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
      </PopupContext.Provider>
    );
  }

  return Toast;
};
