import React from 'react';

interface Ibtn {
  btntext: string;
  other?: string;
  isbuttonactive?: boolean;
  clickEvent?: React.MouseEventHandler<HTMLButtonElement>;
}
export const CustomButton: React.FC<Ibtn> = (props) => {
  const { btntext, other, isbuttonactive, clickEvent } = props;
  return (
    <button
      type="button"
      onClick={clickEvent}
      className={`${
        isbuttonactive
          ? 'bg-activeButton text-white'
          : 'bg-inactiveButton text-placeholdertextcolor'
      } rounded-xl z-40 ${other}`}
      disabled={!isbuttonactive}
    >
      {btntext}
    </button>
  );
};
