import React from 'react';

import freeScoreIcon from '../../../images/free score_icon.svg';
import personalizedInsight from '../../../images/Manage Credit_icon.svg';
import noAdsNoSpam from '../../../images/No ads_no Spam.svg';
import safeAndSecure from '../../../images/Totally Safe_icon.svg';

const BeforeLoginSideBar = () => {
  return (
    <div className="fixed w-30vw bottom-0 top-0 right-0 rounded-l-4xl shadow-sidebarShadow hidden lg:flex flex-col z-30 bg-white items-center gap-y-24 pt-40">
      <div className="flex flex-col w-3/4 ml-10">
        <p className="text-3xl text-numberColor font-medium">
          Everything
        </p>
        <p className="text-4xl text-numberColor font-bold">
          About Credit Score
        </p>
      </div>
      <div className="grid grid-cols-2 w-3/4 gap-y-14">
        <div className="flex flex-col justify-center items-center gap-y-5">
          <img
            src={freeScoreIcon}
            alt=""
            height="73px"
            width="100px"
          />
          <p className="text-center text-xl text-numberColor font-medium">
            Lifetime FREE <br /> Credit Score
          </p>
        </div>
        <div className="flex flex-col justify-center items-center gap-y-5">
          <img
            src={personalizedInsight}
            alt=""
            height="73px"
            width="100px"
          />
          <p className="text-center text-xl text-numberColor font-medium">
            Personalized <br /> Insights
          </p>
        </div>
        <div className="flex flex-col justify-center items-center gap-y-5">
          <img src={noAdsNoSpam} alt="" height="73px" width="100px" />
          <p className="text-center text-xl text-numberColor font-medium">
            No Ads,
            <br />
            No Spam
          </p>
        </div>
        <div className="flex flex-col justify-center items-center gap-y-5">
          <img
            src={safeAndSecure}
            alt=""
            height="73px"
            width="100px"
          />
          <p className="text-center text-xl text-numberColor font-medium">
            100% Safe <br /> And Secure
          </p>
        </div>
      </div>
      <div className="absolute left-0 top-40 w-5 h-20 bg-sidebargreen" />
    </div>
  );
};
export default BeforeLoginSideBar;
