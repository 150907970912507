import React from 'react';
import Lottie from 'react-lottie-player';
import commonLoader from '../AnimationFiles/commonLoader.json';

export function CommonLoader() {
  return (
    <div className=" flex justify-center align-center lg:h-commonLoader-container h-90vh">
      <div className="h-full w-full flex  justify-center items-center">
        <Lottie
          loop
          animationData={commonLoader}
          play
          style={{ width: 200, height: 200 }}
        />
      </div>
    </div>
  );
}
