import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import TagManager from 'react-gtm-module';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { App } from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { DEVELOPMENT, PRODUCTION } from './constants/constants';
import { store } from './app/store';

Sentry.init({
  dsn: `${process.env.REACT_APP_SENTRY_DNS}`,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ['card.fplabs.tech'],
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  tracesSampler: () => 0.4,
  environment: process.env.REACT_APP_ENV,
  ignoreErrors: [
    'getReadModeConfig',
    'getReadModeExtract',
    'getReadModeRender',
    'privateSpecialRepair',
    'removeChild',
  ],
});

const persistor = persistStore(store);
const queryClient = new QueryClient();

if (
  process.env.REACT_APP_ENV === PRODUCTION ||
  process.env.REACT_APP_ENV === DEVELOPMENT
) {
  const tagManagerArgs = {
    gtmId: `GTM-${process.env.REACT_APP_GTMID}`,
    auth: process.env.REACT_APP_GTMAUTH,
    preview: process.env.REACT_APP_GTMENV,
  };

  TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <App />
          <ReactQueryDevtools
            initialIsOpen={process.env.REACT_APP_ENV === PRODUCTION}
            position="bottom-right"
          />
        </QueryClientProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
